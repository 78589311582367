import { ProjectCard } from "./ProjectCard";

export const Projects = () => {
  // Data for rendering project cards.
  const projects = [
    {
      key: "1",
      name: "FreshMovies",
      imgPath: "freshmovies",
      desc: "FreshMovies is a dynamic platform catering to movie enthusiasts around the globe. We are committed to providing our users with the latest movie releases, reviews, and ratings.",
      link: "https://fresh-movies.vercel.app/",
    },
    {
      key: "2",
      name: "Zwiggy",
      imgPath: "zwiggy",
      desc: "Zwiggy is a food delivery app that aims to bring your favorite meals from a variety of restaurants right to your doorstep.",
      link: "https://web-zwiggy.netlify.app/",
    },
  ];

  return (
    <div className="project-main">
      {projects.map((project) => (
        <ProjectCard
          key={project.key}
          projectName={project.name}
          projectImg={project.imgPath}
          projectDesc={project.desc}
          projectLink={project.link}
        />
      ))}
    </div>
  );
};
