import laptopCoding from "./../img/icons8-laptop-coding-48.png";
import mail from "./../img/icons8-macbook-mail-48.png";
import skills from "./../img/icons8-software-development-48.png";
import { NavLink } from "react-router-dom";
export function Header() {
  return (
    <div id="page-header">
      <div id="logo"></div>
      <ul id="page-header-link">
        <li>
          <NavLink className="nav-link" to={"/"}>
            <img src={skills} height={"24px"} width={"24px"} />
            Skills
          </NavLink>
        </li>
        <li>
          <NavLink className="nav-link" to={"/projects"}>
            <img src={laptopCoding} height={"24px"} width={"24px"} />
            Projects
          </NavLink>
        </li>
        <li>
          <NavLink className="nav-link" to={"/contact"}>
            <img src={mail} height={"24px"} width={"24px"} />
            Contact Me
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
