import freshMovieDemoImg from "../img/fresh-movie-demo.jpg";
import zwiggyDemoImg from "../img/zwiggy-project-demo.png";
import preview from "./../img/Eye.png";
export function ProjectCard({
  projectName,
  projectImg,
  projectDesc,
  projectLink,
}) {
  return (
    <div className="project-card">
      <div className="preview-image">
        <img
          src={projectImg === "freshmovies" ? freshMovieDemoImg : zwiggyDemoImg}
          alt={projectName}
          height="240px"
          width="100%"
        />
      </div>
      <div className="project-title">
        <h2>{projectName}</h2>
        <div className="card-main">
          <p className="project-description">{projectDesc}</p>
        </div>
        <div className="card-buttons">
          <button className="github-navigate-button">
            <a
              href="https://github.com/krshubham-1514/fresh-movies"
              target="_blank"
            >
              <i className="devicon-github-original"></i> Github
            </a>
          </button>
          <button className="preview-navigate-button">
            <a href={projectLink} target="_blank">
              <img src={preview} height="16px" width="16px" />
              Preview
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}
