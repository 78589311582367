import instagramLogo from "./../img/Instagram.png";
import linkedinLogo from "./../img/LinkedIn.png";
import twitterLogo from "./../img/TwitterX.png";
import githubLogo from "./../img/GitHub.png";
export function Contact() {
  return (
    <div className="contact-container">
      <div className="contact-container-card">
        <h2>Email</h2>
        <p>krshubham1514@gmail.com</p>
        <h2>Social Media</h2>
        <a href="">
          <img src={linkedinLogo} height={"57px"} width={"57px"} />
          <img src={instagramLogo} height={"57px"} width={"57px"} />
          <img src={twitterLogo} height={"57px"} width={"57px"} />
          <img src={githubLogo} height={"57px"} width={"57px"} />
        </a>
      </div>
    </div>
  );
}
