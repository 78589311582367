import { useEffect } from "react";
import Lottie from "react-lottie";
import animationData from "./../lotties/landing-animation.json";
import resumeFile from "./../files/my-resume.pdf";
import downloadResumeIcon from "./../img/icons8-download-resume-50.png";
export function IntroHeader() {
  const carouselText = [
    { text: "Shubham Kumar", color: "black" },
    { text: "Software Developer", color: "black" },
    { text: "UI/UX Designer", color: "black" },
  ];

  useEffect(function () {
    carousel(carouselText, document.getElementById("feature-text"));
  }, []);

  async function typeSentence(sentence, eleRef, delay = 100) {
    const letters = sentence.split("");
    let i = 0;
    while (i < letters.length) {
      await waitForMs(delay);
      eleRef.append(letters[i]);
      i++;
    }
    return;
  }

  async function deleteSentence(eleRef) {
    const sentence = eleRef.innerText;
    const letters = sentence.split("");
    let i = 0;
    while (letters.length > 0) {
      await waitForMs(100);
      letters.pop();
      eleRef.innerText = letters.join("");
    }
  }

  async function carousel(carouselList, eleRef) {
    var i = 0;
    while (true) {
      updateFontColor(eleRef, carouselList[i].color);
      await typeSentence(carouselList[i].text, eleRef);
      await waitForMs(1500);
      await deleteSentence(eleRef);
      await waitForMs(500);
      i++;
      if (i >= carouselList.length) {
        i = 0;
      }
    }
  }
  function updateFontColor(eleRef, color) {
    eleRef.style.color = color;
  }

  function waitForMs(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div id="landing-page-cotainer">
      <div class="typing-container">
        <div>
          <span id="fixed-text">Hi, I am</span>
          <br />
          <span id="feature-text"></span>
          <span class="input-cursor"></span>
        </div>
        <br />
        <button className="resume-button">
          <img src={downloadResumeIcon} height="24px" width="24px" />
          <a href={resumeFile} download={"shubham-resume"}>
            Download Resume
          </a>
        </button>
      </div>
      <div className="profile-photo-container">
        <Lottie options={defaultOptions} height="70vh" width="100%" />
      </div>
    </div>
  );
}
