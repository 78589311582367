import React from "react";
import skillLogo from "./../img/Innovation.png";

export function Skills() {
  const logoHeight = "45px";
  const logoWidth = "45px";
  return (
    <div id="skill-container">
      <div className="skill-badge-container">
        <img src={skillLogo} height={"32px"} width={"32px"} />
        {/* <p id="skill-badge-text">Skills</p> */}
      </div>
      <marquee className="skill-card">
        <img
          className="skill-card-image"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg"
          height={logoHeight}
          width={logoWidth}
        />
        <img
          className="skill-card-image"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/angularjs/angularjs-original.svg"
          height={logoHeight}
          width={logoWidth}
        />
        <img
          className="skill-card-image"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg"
          height={logoHeight}
          width={logoWidth}
        />
        <img
          className="skill-card-image"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vuejs/vuejs-original.svg"
          height={logoHeight}
          width={logoWidth}
        />
        <img
          className="skill-card-image"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg"
          height={logoHeight}
          width={logoWidth}
        />
        <img
          className="skill-card-image"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/java/java-original.svg"
          height={logoHeight}
          width={logoWidth}
        />
        <img
          className="skill-card-image"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/c/c-original.svg"
          height={logoHeight}
          width={logoWidth}
        />
        <img
          className="skill-card-image"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg"
          height={logoHeight}
          width={logoWidth}
        />
        <img
          className="skill-card-image"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/php/php-original.svg"
          height={logoHeight}
          width={logoWidth}
        />
        <img
          className="skill-card-image"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg"
          height={logoHeight}
          width={logoWidth}
        />
        <img
          className="skill-card-image"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jest/jest-plain.svg"
          height={logoHeight}
          width={logoWidth}
        />
        <img
          className="skill-card-image"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cypressio/cypressio-original.svg"
          height={logoHeight}
          width={logoWidth}
        />
        <img
          className="skill-card-image"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flutter/flutter-original.svg"
          height={logoHeight}
          width={logoWidth}
        />
        <img
          className="skill-card-image"
          src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/android/android-original.svg"
          height={logoHeight}
          width={logoWidth}
        />
      </marquee>
    </div>
  );
}
