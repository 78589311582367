import { createRoot } from "react-dom";
import { App } from "./app";
import { Dashboard } from "./components/Dashboard";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<Dashboard />} />
      <Route path="projects" element={<Projects />} />
      <Route path="contact" element={<Contact />} />
    </Route>
  )
);
const container = document.getElementById("app-root");
const root = createRoot(container);
root.render(<RouterProvider router={router} />);
