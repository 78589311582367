import { IntroHeader } from "./IntroHeader";
import { Skills } from "./Skills";

export function Dashboard() {
  return (
    <div>
      <IntroHeader />
      <Skills />
    </div>
  );
}
